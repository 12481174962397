import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const TranspPage = () => {

    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=transport_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Transport</h1>



                    <h2>Ankomst med bil</h2>
                    <p>
                        For dem som velger å ankomme med bil finner man eventområdet ved å reise i retning av "Marathonas". Rett før ankomst
                        Marathonas (om man kommer fra Nea Makri-siden) følger man så skilting mot "Schinias".
                        Før veien skal til å vende mot venstre inn i nasjonalparken er det en avkjøring på høyre hånd som er skiltet
                        til "Karavi" (Καραβι på gresk). Ta av på denne avkjøringen og etter et par hunde meter vil innkjøringen til
                        "<a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti</a>" ("Γαλάζια Ακτή" på gresk) ligge på venstre side av veien.
                    </p>


                    <h2>Parkering</h2>
                    <p>
                        For dem som eventuelt vil ankomme i egen bil
                        har bryllupslokalet en stor parkering rett utenfor.
                    </p>

                    {/*<h2>Retur etter bryllupsfesten</h2>
                    <p>
                        Vi vurderer å sette opp enkel skytteltransport etter
                        bryllupsfesten som kjører gjester hjem til stedet
                        de bor i nærområdet.
                    </p>*/}

                </>
            }
            {lang == "en" &&
                <>
                    <h1>Transport</h1>

                    <h2>Arrival by car</h2>
                    <p>
                        For those of you that arrive by car travel in the direction of "Marathonas". Right before entering
                        Marathonas (from the side of Nea Makri) follow the signs towards "Schinias".
                        Just before the road is to turn left into the netional park there is an exit on the right hand side
                        with a sign towards "Karavi" (spelled Καραβι in Greek). Take this exit and after a couple of hundered metres the entrance to
                        "<a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti</a>" ("Γαλάζια Ακτή" in Greek) will be on the right hand side of the road.
                    </p>


                    <h2>Parking</h2>
                    <p>
                        For those that attend the wedding by car there is plenty
                        of parking available just outside the event venue.
                    </p>

                    {/*<p>
                        For those spending the night in the Marathonas area we are considering to arrange
                        van transport back to the various hotels and other places people are staying.
                    </p>*/}
                </>
            }

        </main>
    )
}

export default TranspPage
